// /styles/SearchPage.scss

.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: #f5f7f6; // Light eco-friendly background
    color: #333;
    padding: 20px;
    text-align: center;
}

.search-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #fe610c; // Eco-friendly green tone
    margin-bottom: 1.5rem;
}

.search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
}

.search-input {
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    transition: border-color 0.2s;

    &:focus {
        outline: none;
        border-color: #fe610c; // Match title color for focus
    }
}

.search-button {
    padding: 10px 20px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #ea661d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #fe610c; // Darken on hover
    }
}

// Add SEO and performance-friendly styles
@media (max-width: 600px) {
    .search-title {
        font-size: 2rem;
    }
    .search-input, .search-button {
        font-size: 1rem;
    }
}
