/* MenubarComponent.scss */
.luxury-menubar {

    /* ไล่เฉดสี Background 5 สเต็ป: #cd3217 → #ec5124 → #ea7818 → #ec5124 → #cd3217 */
    background: linear-gradient(45deg, #241c1a 0%, #58352c 25%, #c82d06 50%, #4b0f00 75%, #310303 100%);
    color: #4a0303;

    /* เงา 3D รอบตัวเมนู (ถ้าต้องการ) */
    box-shadow: 0 4px 8px rgb(190 25 25 / 50%);
    padding: 20px;
    font-size: 15px;
    border: 0px solid #999999;
    border-radius: 10px;
    margin: 10px;

    .p-menubar-button {
        color: #d93a1e;
        margin: 0px 0px 0px 30px;


    }

    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        background: unset;
    }

    @media screen and (max-width: 960px) {
        .p-menubar-root-list {
            background: #310b02eb;
        }
    }

    .p-menuitem-content,
    .p-submenu-list {
        &:hover {
            background: #d93a1e;
        }
    }

    .custom-dropdown {

        border-radius: 4px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

        .p-menuitem-content,
        .p-submenu-list {
            background: #f22a1b;
            padding-left: 2px;
        }
    }



    /* จัดการ PrimeReact Menubar ภายใน */
    .p-menubar {
        background-color: transparent;
        /* โปร่งใส, ใช้ gradient ด้านนอกแทน */
        border: none;
        /* ไม่มีเส้นขอบ */
        min-height: 60px;
        /* ความสูงเมนู, ปรับตามต้องการ */





        /* สไตล์รายการหลัก (เมนูระดับบน) */
        .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
            /* สีข้อความหลักเป็นดำ */
            color: black;
            /* เพิ่ม text-shadow เล็กน้อยเพื่ออารมณ์ 3D */
            text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4),
                0 0 3px rgba(0, 0, 0, 0.5);
            font-weight: 600;
            /* ตัวหนาเล็กน้อย */
            transition: all 0.3s ease;

            &:hover {
                /* เปลี่ยนสี / ใส่เงาเวลา Hover */
                background: #d93a1e;
                color: #ffffff;
                /* อาจเลือกเป็นขาว/เหลือง/สีอื่น */
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
                transform: translateY(-2px);
                /* ขยับขึ้นเล็กน้อย สร้างเอฟเฟกต์ 3D */
            }
        }




        /* สไตล์เมนูย่อย (Dropdown) */
        .p-submenu-list {
            /* ไล่เฉดสีใน Dropdown ด้วย */
            background: linear-gradient(45deg, #ec5124, #ea7818, #ec5124);
            border-radius: 4px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

            /* link ภายใน dropdown */
            .p-menuitem-link {
                color: black;
                text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.3);

                &:hover {
                    background: #d93a1e;
                    color: #000000;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
                }
            }
        }
    }

    /* สไตล์โลโก้ด้านซ้าย (img) */
    img {
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.1) rotate(-2deg);
            /* ขยายและหมุนเล็กน้อย */
        }
    }

    .p-menuitem-text,
    .p-menuitem-icon {
        color: #d9d9d9;
        font-size: 17px;

        &:hover {
            color: #ffffff;
        }

    }
}