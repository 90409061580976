// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/styles/App.scss



  
.App {
    color: white;
    background: linear-gradient(to left, #1f001e, #080808, #000000, #080808, #1f0200); // Gradient on the edges   

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .custom-card {
    background: #202020;
    color: #ababab;
    border-radius: 0.25rem;
  }

  .custom-swiper-slide {
    overflow: hidden; // Ensures no content spills out of the curved edges
    transform: scale(0.9); // Slightly scale down slides to make curve more noticeable
    transition: transform 0.3s ease-in-out; // Smooth transition for the scaling effect

    &:hover {
      transform: scale(1); // Scale up slide on hover for a dynamic interaction effect
    }
  }

  .custom-image {
    width: 100%;
    height: auto;
    object-fit: cover; // Ensure full coverage without distortion
  }  

}

html {
    scroll-behavior: smooth;
  }



.color-index1 {
  color: blanchedalmond;
}

.color-index2 {
  color: black;
}
