.posts-swiper-container {
    margin: 20px auto;
    max-width: 1200px;
  
    .swiper-slide-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  
      .swiper-image {
        width: 100%;
        max-height: 250px;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: scale(1.05); // เพิ่มเอฟเฟ็กต์ hover
        }
      }
  
      .swiper-caption {
        margin-top: 10px;
  
        h3 {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          margin: 5px 0;
        }
  
        p {
          font-size: 14px;
          color: #666;
          margin: 0;
        }
      }
    }
  }
  