// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/styles/Footer.scss
.custom-footer {
    padding: 20px;
    background: linear-gradient(to left, #cd3217, #ec5124, #ea7818, #ec5124, #cd3217);
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  
    a {
      color: #f4b341;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  