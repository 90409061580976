// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/styles/PostDetails.scss

$postDetailsBg: #000; // Dark background color
$footerBg: #333; // Darker footer background color
$footerTextColor: #fff; // White text color for the footer
$contentBg: linear-gradient(to bottom, #0c0c0c, $postDetailsBg); // Gradient for content background
$bodyTextColor: #e84724; // Body text color

a {
  text-decoration: none;
}

.postDetailsContainer {
  background-color: $postDetailsBg;
  color: $footerTextColor;

  .content {
    background: $contentBg;
    color: $bodyTextColor;
    background-size: cover;
    background-position: center;
  }

  .footer {
    background-color: $footerBg;
    color: $footerTextColor;
    padding: 20px;
    text-align: center;
  }

  .card-header {
    display: flex;
    align-items: center;
    h2 {
      margin-left: 10px; // Adjust spacing between button and title
    }
  }

  .p-card-title {
    font-size: 40px; 
  }

  .p-card-body{
    margin-bottom: 5%;
  }

}

.p-bode-index {
  background: #0f0f0f;
  color: #b3b3b3;
  width: 100%;
  margin-bottom: 20px;

  font-size: larger;
  line-height: 34px;
}


.p-card-index {
  font-size: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pointer {
  cursor: pointer;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.footer-index1{
  background: linear-gradient(to left, #878787, #d2d2d2, #eeeeee);
    padding: 1%;
}

.content-index1{
  overflow-wrap: break-word;
    word-break: break-all;
    line-height: 2.5;
    font-size: medium;
}