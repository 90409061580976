// /styles/SearchResults.scss

.search-results-container {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;

    .p-card-title{
        font-size: 0.8rem;
    }

    .p-card{
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2);
    }

    .fontx{
        font-size: x-small;
    }

}

.chip-container {
    display: contents;
    flex-wrap: wrap;
    justify-content: flex-start;  // Chips will be displayed in one row, wrapping if needed
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.word-chip {
    max-width: 300px; /* Default maximum width for larger screens */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add "..." for overflow text */
    cursor: pointer;
    font-size: 0.9rem;

    @media (max-width: 768px) {
        max-width: 110px; /* Set max width to 100px for mobile screens */
    }
}


.high-frequency {
    background-color: #e32200;
    color: #fff;
}

.medium-frequency {
    background-color: #ff7e54;
    color: #fff;
}

.low-frequency {
    background-color: #ffaa77;
    color: #843200;
}



.post-title-chip {
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.9rem;
}

.result-count {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.results-grid {
    display: grid;
    grid-template-columns: 1fr; // Default to 1 column on very small screens
    gap: 1rem;
    margin-top: 1.5rem;

    @media (min-width: 390px) {
        grid-template-columns: repeat(2, 1fr); // 2 columns for small screens

        
    }

    @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr); // 2 columns for slightly larger screens
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr); // 3 columns for tablets
    }

    @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr); // 4 columns for desktops
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr); // 5 columns for large desktops
    }
}

.result-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.result-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.05);
    }
}
