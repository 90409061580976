// PostsList.scss
.posts-list-container {
    .row {
      display: flex;
      flex-wrap: wrap;
    }
  
    .col-md-4 {
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
  
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  
    .p-card {
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 4px 12px rgba(0, 128, 0, 0.1);  // Soft green shadow for eco feel
      background-color: #f9f9f5; // Light eco-friendly color
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  
      &:hover {
        transform: scale(1.03);
        box-shadow: 0px 6px 16px rgba(0, 128, 0, 0.15);  // Enhanced shadow on hover
      }
  
      .p-card-title {
        font-size: 1rem;  // Set title font size
        color: #560d00;   // Dark green for eco theme
        font-weight: 600;
      }
  
      .p-card-subtitle {
        font-size: 0.85rem;
        color: #7b7b7b;
      }
  
      img {
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }
  